import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const { setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") setUsername(value);
    else if (name === "password") setPassword(value);
    else if (name === "newPassword") setNewPassword(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const cognitoUser = await Auth.signIn(username, password);

      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(cognitoUser);
      } else {
        setIsLoggedIn(true);
        navigate("/dashboard");
      }
    } catch (err) {
      setError(err.message || "An error occurred during sign-in");
    }
  };

  const handleNewPassword = async () => {
    try {
      await Auth.completeNewPassword(user, newPassword);
      setPasswordChanged(true);
      setIsLoggedIn(true);
      navigate("/dashboard");
    } catch (err) {
      setError(err.message || "An error occurred while setting new password");
    }
  };

  return (
    <div>
      <h1>Login</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {passwordChanged ? (
        <p style={{ color: "green" }}>New password set successfully!</p>
      ) : user ? (
        <div>
          <label>
            New Password:
            <input
              type="password"
              name="newPassword"
              value={newPassword}
              onChange={handleInputChange}
            />
          </label>
          <button onClick={handleNewPassword}>Set New Password</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={username}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />
          </label>
          <button type="submit">Login</button>
        </form>
      )}
    </div>
  );
}

export default Login;
