import React from 'react';
import translations from './translations.json';
import { useLanguage } from './LanguageContext';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import meta from './meta.jpg'; // Assuming you're using the same image

function About() {
  const { language } = useLanguage();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>{translations[language].aboutTitle}</h1>
        </Grid>
        <Grid item xs={isSmallScreen ? 12 : 8}>
          <p>{translations[language].aboutText}</p>
          <p>{translations[language].aboutText2}</p>
          <p>{translations[language].aboutText3}</p>
          <p>{translations[language].aboutText4}</p>
          <p>{translations[language].aboutText5}</p>
          {/* Additional paragraphs */}
        </Grid>
        {!isSmallScreen && (
          <Grid item xs={4}>
            <img src={meta} alt="Meta" style={{ maxWidth: '100%', height: 'auto' }} />
          </Grid>
        )}
        {/* Add more grid items here as needed */}
      </Grid>
    </Box>
  );
}

export default About;
