// Code Sample 6: TopBarMenu.js with Language Switcher Always Visible and Other Buttons Hidden on Small Screens
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Hidden from "@mui/material/Hidden";
import { useAuth } from "./AuthContext";
import { Auth } from "aws-amplify";
import { useLanguage } from "./LanguageContext";
import translations from "./translations.json";

export default function TopBarMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { language, setLanguage } = useLanguage();
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setIsLoggedIn(false);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#182030' }}>
      <Toolbar>
        <Hidden mdUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Maat No.127
        </Typography>
        {/* Language Switcher Always Visible */}
        <Typography variant="body1" style={{ marginRight: 20 }}>
          <button onClick={() => handleLanguageChange("en")}>EN</button>
          <button onClick={() => handleLanguageChange("lv")}>LV</button>
          <button onClick={() => handleLanguageChange("ru")}>RU</button>
        </Typography>
        <Hidden smDown>
          {" "}
          <Typography variant="body1" style={{ marginRight: 20 }}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              {translations[language].home}
            </Link>
          </Typography>
          <Typography variant="body1" style={{ marginRight: 20 }}>
            <Link
              to="/about"
              style={{ textDecoration: "none", color: "white" }}
            >
              {translations[language].about}
            </Link>
          </Typography>
          <Typography variant="body1" style={{ marginRight: 20 }}>
            <Link
              to="/contact"
              style={{ textDecoration: "none", color: "white" }}
            >
              {translations[language].contact}
            </Link>
          </Typography>
          {isLoggedIn && (
            <Typography variant="body1" style={{ marginRight: 20 }}>
              <Link
                to="/dashboard"
                style={{ textDecoration: "none", color: "white" }}
              >
                {translations[language].dashboard}
              </Link>
            </Typography>
          )}
          <Typography variant="body1">
            {isLoggedIn ? (
              <Link
                onClick={handleLogout}
                style={{ textDecoration: "none", color: "white" }}
              >
                {translations[language].logout}
              </Link>
            ) : (
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "white" }}
              >
                {translations[language].login}
              </Link>
            )}
          </Typography>
        </Hidden>
      </Toolbar>
      <Hidden mdUp>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} component={Link} to="/">
            {translations[language].home}
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/about">
            {translations[language].about}
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/contact">
            {translations[language].contact}
          </MenuItem>
          {isLoggedIn && (
            <MenuItem onClick={handleClose} component={Link} to="/dashboard">
              {translations[language].dashboard}
            </MenuItem>
          )}
          <MenuItem
            onClick={isLoggedIn ? handleLogout : handleClose}
            component={isLoggedIn ? "div" : Link}
            to={isLoggedIn ? null : "/login"}
          >
            {isLoggedIn
              ? translations[language].logout
              : translations[language].login}
          </MenuItem>
        </Menu>
      </Hidden>
    </AppBar>
  );
}
