import React from 'react';
import translations from './translations.json';
import { useLanguage } from './LanguageContext';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import home from './home.jpg';

function Home() {
  const { language } = useLanguage();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>{translations[language].homeTitle}</h1>
        </Grid>
        {!isSmallScreen && (
          <Grid item xs={4}>
            <img src={home} alt="Home" style={{ maxWidth: '100%', height: 'auto' }} />
          </Grid>
        )}
        <Grid item xs={isSmallScreen ? 12 : 8}>
          <p>{translations[language].homeText}</p>
          {/* Additional paragraphs */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
