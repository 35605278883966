import React from 'react';
import translations from './translations.json';
import { useLanguage } from './LanguageContext';

function Contact() {
  const { language } = useLanguage();

  return (
    <div>
      <h1>{translations[language].contactTitle}</h1>
      <p>Email: info@brivmurnieciba.lv</p>
      <p>Facebook: <a href="https://facebook.com/MaatNo127" target="_blank" rel="noopener noreferrer">facebook.com/MaatNo127</a></p>
    </div>
  );
}

export default Contact;
