// Code Sample 11: Updated Dashboard.js
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import axios from "axios";

function Dashboard() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [files, setFiles] = useState([]);
  //const response = await axios.get("http://localhost:3001/api/list-files");

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsLoggedIn(true);
      } catch (error) {
        setIsLoggedIn(false);
      }
    };

    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          "http://localhost:3001/api/list-files"
        ); // Replace with your backend API endpoint
        setFiles(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("An error occurred while fetching data: ", error);
      }
    };

    checkAuthState();
    if (isLoggedIn) {
      fetchFiles();
    }
  }, [isLoggedIn]);

  return (
    <div>
      <h1>Dashboard</h1>
      {isLoggedIn ? (
        <>
          <p>Welcome to your dashboard. You are logged in!</p>
          <h2>Your Files:</h2>
          <ul>
            {files.map((file, index) => (
              <li key={index}>
                {file.Key}{" "}
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  Download
                </a>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div>
          <p>Only authorized users can see this.</p>
          <Link to="/login">Login Here</Link>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
