import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context for the language
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Set the default language to Latvian ('lv')
  const [language, setLanguage] = useState("lv");

  // You can add more logic here to load/save the language from local storage or API

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
